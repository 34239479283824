import moment from 'moment';

export const get = (value: Date | string | number = new Date()) => {
  let momentData: any;
  if (typeof value === 'number') {
    momentData = moment.unix(value).utc();
  }
  else {
    momentData = moment.parseZone(value);
  }
  if (momentData === 'Invalid Date' || momentData.format() === 'Invalid date') {
    return { format: () => '-' };
  }
  return momentData;
};

const getLocal = (value: Date | string | number) => {
  let momentData: any;
  if (typeof value === 'number') {
    momentData = moment.unix(value);
  }
  else {
    momentData = moment(value);
  }
  if (momentData === 'Invalid Date') {
    return { format: () => '-' };
  }
  return momentData;
};
export const formatMonthDateTime = (value: Date | string | number) => {
  return get(value).format('DD MMM \'YY HH:mm');
};

export const formatDateMonthYearLocal = (value: Date | string | number) => {
  return getLocal(value).format('DD MMM \'YY HH:mm');
};
export const formatMonthDateTimeToLocal = (value: Date | string | number) => {
  if (value) {
    return get(value)?.local().format('DD MMM \'YY HH:mm');
  }
};
export const formatDateMonthYearOnlyLocal = (value: Date | string | number) => {
  return getLocal(value).format('DD MMM YYYY');
};

export const formatTimeLocal = (value: Date | string | number) => {
  return getLocal(value).format('HH:mm');
};
export const formatTime = (value: Date | string | number, format = 'HH:mm') => {
  return get(value).format(format);
};
export const formatTimeWithGMTSuffix = (value: Date | string | number) => {
  return get(value).format('HH:mm [(GMT]Z[)]');
};
export const getTimeZone = (value: Date | string | number) => {
  return get(value).format('[GMT]Z[]');
};
export const formatDateMonthYearOnly = (value: Date | string | number) => {
  return get(value).format('DD MMM YYYY');
};

export const formatMonthDateTimeWithGMTSuffix = (value: Date | string | number) => {
  return get(value).format('DD MMM \'YY HH:mm [(GMT]Z[)]');
};
